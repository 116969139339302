import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './OfferForm.css';

// Function to get today's date in YYYY-MM-DD format
const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
};

// Validation schema using Yup
const validationSchema = Yup.object({
    offerNameEn: Yup.string()
        .required('Offer Name (En) is required')
        .min(3, 'Offer Name (En) must be at least 3 characters')
        .matches(/^[A-Za-z0-9\s!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]+$/, 'Offer Name (En) can contain only English characters, numbers, and symbols'),
    offerNameAr: Yup.string()
        .required('Offer Name (Ar) is required')
        .min(3, 'Offer Name (Ar) must be at least 3 characters')
        .matches(/^[\u0600-\u06FF0-9\s!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]+$/, 'Offer Name (Ar) can contain only Arabic characters, numbers, and symbols'),
    offerDescriptionEn: Yup.string()
        .required('Offer Description (En) is required')
        .min(3, 'Offer Description (En) must be at least 3 characters')
        .matches(/^[A-Za-z0-9\s!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]+$/, 'Offer Description (En) can contain only English characters, numbers, and symbols'),
    offerDescriptionAr: Yup.string()
        .required('Offer Description (Ar) is required')
        .min(3, 'Offer Description (Ar) must be at least 3 characters')
        .matches(/^[\u0600-\u06FF0-9\s!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]+$/, 'Offer Description (Ar) can contain only Arabic characters, numbers, and symbols'),
    offerStartDate: Yup.date()
        .required('Offer Start Date is required')
        .min(getTodayDate(), 'Offer Start Date cannot be in the past'),
    offerEndDate: Yup.date()
        .required('Offer End Date is required')
        .min(Yup.ref('offerStartDate'), 'Offer End Date must be at least one day after the Offer Start Date'),
    offerBanner: Yup.mixed()
        .required('Offer Banner is required')
        .test('fileFormat', 'Offer Banner must be in png, jpeg, or jpg format', (value) =>
            value ? ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type) : true
        ),
});

const OfferForm = ({ offerId, isEdit = false }) => {
    const [existingBanner, setExistingBanner] = useState(null);

    const formik = useFormik({
        initialValues: {
            offerNameEn: '',
            offerNameAr: '',
            offerDescriptionEn: '',
            offerDescriptionAr: '',
            offerStartDate: getTodayDate(),
            offerEndDate: '',
            offerBanner: null,
            vehicles: [],
            relatedVehicles: []
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { resetForm }) => {
            let data;
            let headers = {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            };

            // Conditionally handle submission format based on isEdit flag
            if (isEdit) {
                // JSON format submission for edit mode
                data = {
                    offer_name_en: values.offerNameEn,
                    offer_name_ar: values.offerNameAr,
                    offer_description_en: values.offerDescriptionEn,
                    offer_description_ar: values.offerDescriptionAr,
                    offer_start_date: values.offerStartDate,
                    offer_end_date: values.offerEndDate,
                    vehicles: values.vehicles,
                    related_vehicles: values.relatedVehicles,
                };
                headers['Content-Type'] = 'application/json';
            } else {
                // FormData submission for new offer
                data = new FormData();
                data.append("offer_name_en", values.offerNameEn);
                data.append("offer_name_ar", values.offerNameAr);
                data.append("offer_description_en", values.offerDescriptionEn);
                data.append("offer_description_ar", values.offerDescriptionAr);
                data.append("offer_start_date", values.offerStartDate);
                data.append("offer_end_date", values.offerEndDate);
                data.append("offer_banner", values.offerBanner);

                values.vehicles.forEach((vehicle, index) => {
                    data.append(`vehicles[${index}]`, vehicle);
                });

                values.relatedVehicles.forEach((vehicle, index) => {
                    data.append(`related_vehicles[${index}]`, vehicle);
                });

                headers['Content-Type'] = 'multipart/form-data';
            }

            try {
                const response = await axios({
                    method: isEdit ? 'put' : 'post',
                    url: isEdit
                        ? `https://beta.api.nhcholding.com/api/offers/${offerId}/edit`
                        : 'https://beta.api.nhcholding.com/api/offers',
                    data: data,
                    headers: headers,
                });

                if (response.status === (isEdit ? 200 : 201)) {
                    toast.success(isEdit ? 'Offer updated successfully!' : 'Offer created successfully!');
                    resetForm();
                } else {
                    toast.error('An error occurred. Please try again.');
                }
            } catch (error) {
                console.error("Error submitting offer:", error);
                toast.error('Offer submission failed. Please check your inputs and try again.');
            }
        }
    });

    useEffect(() => {
        if (isEdit && offerId) {
            const fetchOffer = async () => {
                try {
                    const response = await axios.get(`https://beta.api.nhcholding.com/api/offers/${offerId}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    });
                    const offer = response.data;

                    formik.setValues({
                        offerNameEn: offer.offer_name_en || '',
                        offerNameAr: offer.offer_name_ar || '',
                        offerDescriptionEn: offer.offer_description_en || '',
                        offerDescriptionAr: offer.offer_description_ar || '',
                        offerStartDate: offer.offer_start_date || getTodayDate(),
                        offerEndDate: offer.offer_end_date || '',
                        offerBanner: null,
                        vehicles: offer.vehicles || [],
                        relatedVehicles: offer.related_vehicles || []
                    });
                    setExistingBanner(offer.offer_banner_url); // Assuming API returns `offer_banner_url`
                } catch (error) {
                    console.error('Error fetching offer data:', error);
                    toast.error('Failed to load offer data.');
                }
            };

            fetchOffer();
        }
    }, [isEdit, offerId]);

    const vehicleOptions = [
        'Dzire', 'Baleno', 'Ciaz', 'Swift', 'Fronx', 'Grand Vitara',
        'Ertiga', 'Jimny 5 Doors', 'Jimny', 'Carry', 'Eeco'
    ];

    return (
        <div className="offer-form">
            <ToastContainer />
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col md={12}>
                        {/* Offer Banner */}
                        <Form.Group controlId="offerBanner">
                            <Form.Label>Offer Banner<span className='required-mark'>*</span> <small>(Offer Banner cannot be more than 2 MB)</small></Form.Label>
                            {existingBanner && (
                                <div className="mb-3">
                                    <img src={existingBanner} alt="Current Offer Banner" width="100%" />
                                    <small className="text-muted">Current banner image</small>
                                </div>
                            )}
                            <Form.Control
                                type="file"
                                accept=".png, .jpeg, .jpg"
                                onChange={(event) => {
                                    const file = event.currentTarget.files[0];
                                    formik.setFieldValue("offerBanner", file);
                                }}
                                isInvalid={formik.touched.offerBanner && !!formik.errors.offerBanner}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.offerBanner}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="offerNameEn">
                            <Form.Label>Offer Name (En)<span className='required-mark'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                {...formik.getFieldProps('offerNameEn')}
                                isInvalid={formik.touched.offerNameEn && !!formik.errors.offerNameEn}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.offerNameEn}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="offerNameAr">
                            <Form.Label>Offer Name (Ar)<span className='required-mark'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                {...formik.getFieldProps('offerNameAr')}
                                isInvalid={formik.touched.offerNameAr && !!formik.errors.offerNameAr}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.offerNameAr}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="offerDescriptionEn">
                            <Form.Label>Offer Description (En)<span className='required-mark'>*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                {...formik.getFieldProps('offerDescriptionEn')}
                                isInvalid={formik.touched.offerDescriptionEn && !!formik.errors.offerDescriptionEn}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.offerDescriptionEn}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="offerDescriptionAr">
                            <Form.Label>Offer Description (Ar)<span className='required-mark'>*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                {...formik.getFieldProps('offerDescriptionAr')}
                                isInvalid={formik.touched.offerDescriptionAr && !!formik.errors.offerDescriptionAr}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.offerDescriptionAr}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="offerStartDate">
                            <Form.Label>Offer Start Date<span className='required-mark'>*</span></Form.Label>
                            <Form.Control
                                type="date"
                                min={getTodayDate()}
                                {...formik.getFieldProps('offerStartDate')}
                                isInvalid={formik.touched.offerStartDate && !!formik.errors.offerStartDate}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.offerStartDate}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="offerEndDate">
                            <Form.Label>Offer End Date<span className='required-mark'>*</span></Form.Label>
                            <Form.Control
                                type="date"
                                min={formik.values.offerStartDate ? formik.values.offerStartDate : getTodayDate()}
                                {...formik.getFieldProps('offerEndDate')}
                                isInvalid={formik.touched.offerEndDate && !!formik.errors.offerEndDate}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.offerEndDate}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Offers on Vehicle</Form.Label>
                            <div className="checkbox-group">
                                {vehicleOptions.map((vehicle) => (
                                    <Form.Check
                                        key={vehicle}
                                        type="checkbox"
                                        id={`offerVehicle_${vehicle}`}
                                        label={vehicle}
                                        value={vehicle}
                                        checked={formik.values.vehicles.includes(vehicle)}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                formik.setFieldValue("vehicles", [...formik.values.vehicles, vehicle]);
                                            } else {
                                                formik.setFieldValue("vehicles", formik.values.vehicles.filter((v) => v !== vehicle));
                                            }
                                        }}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Related Vehicles</Form.Label>
                            <div className="checkbox-group">
                                {vehicleOptions.map((vehicle) => (
                                    <Form.Check
                                        key={vehicle}
                                        type="checkbox"
                                        id={`relatedVehicle_${vehicle}`}
                                        label={vehicle}
                                        value={vehicle}
                                        checked={formik.values.relatedVehicles.includes(vehicle)}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                formik.setFieldValue("relatedVehicles", [...formik.values.relatedVehicles, vehicle]);
                                            } else {
                                                formik.setFieldValue("relatedVehicles", formik.values.relatedVehicles.filter((v) => v !== vehicle));
                                            }
                                        }}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Col>

                    <Col md={12} className="text-end">
                        <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
                            {isEdit ? 'Update Offer' : 'Submit'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default OfferForm;
