import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table, Button, Pagination, Form, Row, Col, Badge } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserRoleTable = ({ isMarketingManager }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [departmentFilter, setDepartmentFilter] = useState('');
    const [roleFilter, setRoleFilter] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        if (location.state?.updated) {
            fetchUsers();
        }
    }, [location.state]);

    const fetchUsers = async () => {
        const token = localStorage.getItem('token');
        const userRoles = JSON.parse(localStorage.getItem('userRoles')) || [];

        try {
            const endpoint = userRoles.includes('mr-manager')
                ? 'https://beta.api.nhcholding.com/api/user/marketing'
                : 'https://beta.api.nhcholding.com/api/users';

            const response = await axios.get(endpoint, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setUsers(response.data);
        } catch (error) {
            toast.error('Failed to load users');
        } finally {
            setLoading(false);
        }
    };

    const getStatusText = (status) => {
        const statusMapping = {
            admin: 'Admin',
            'mr-user': 'Marketing User',
            'mr-manager': 'Marketing Manager',
            it: 'IT User',
            manager: 'Manager',
            user: 'User',
            'website-user': 'Website User',
        };
        return statusMapping[status] || status;
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                const response = await axios.delete(`https://beta.api.nhcholding.com/api/users/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (response.status === 204) {
                    setUsers(users.filter((user) => user.id !== id));
                    toast.success('User deleted successfully');
                } else {
                    toast.error('Failed to delete user');
                }
            } catch (error) {
                toast.error('Error deleting user. Please try again.');
            }
        }
    };

    const filteredUsers = users.filter((user) => {
        const matchesSearch = user.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesDepartment = departmentFilter ? user.department.name === departmentFilter : true;
        const matchesRole = roleFilter
            ? user.roles.some((role) => getStatusText(role.name) === roleFilter)
            : true;

        return matchesSearch && matchesDepartment && matchesRole;
    });

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) return <div>Loading users...</div>;

    return (
        <>
            <ToastContainer />
            <Form className="mb-3">
                <Row>
                    <Col md={4}>
                        <Form.Control
                            type="search"
                            placeholder="Search by User Name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="input-brand-shadow"
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            as="select"
                            value={departmentFilter}
                            onChange={(e) => setDepartmentFilter(e.target.value)}
                            className="input-brand-shadow"
                        >
                            <option value="">Department</option>
                            {[...new Set(users.map((user) => user.department.name))].map((department) => (
                                <option key={department} value={department}>
                                    {department}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            as="select"
                            value={roleFilter}
                            onChange={(e) => setRoleFilter(e.target.value)}
                            className="input-brand-shadow"
                        >
                            <option value="">Role</option>
                            {[...new Set(users.flatMap((user) => user.roles.map((role) => getStatusText(role.name))))].map(
                                (role) => (
                                    <option key={role} value={role}>
                                        {role}
                                    </option>
                                )
                            )}
                        </Form.Control>
                    </Col>
                    <Col md={2} className="text-end">
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setSearchTerm('');
                                setDepartmentFilter('');
                                setRoleFilter('');
                            }}
                        >
                            Clear Filters
                        </Button>
                    </Col>
                </Row>
            </Form>

            <div className="table-brand-shadow">
                <Table responsive striped bordered hover className="text-center">
                    <thead className="brand-table-head">
                        <tr>
                            <th>Sr. No</th>
                            <th>User Id</th>
                            <th>User Name</th>
                            <th>Email Address</th>
                            <th>Department</th>
                            <th>Role</th>
                            {!isMarketingManager && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {currentUsers.length > 0 ? (
                            currentUsers.map((user, index) => (
                                <tr key={user.id}>
                                    <td>{indexOfFirstUser + index + 1}</td>
                                    <td>{user.id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.department.name}</td>
                                    <td>
                                        {user.roles.map((role, i) => (
                                            <Badge key={i} bg="primary" className="me-1">
                                                {getStatusText(role.name)}
                                            </Badge>
                                        ))}
                                    </td>
                                    {!isMarketingManager && (
                                        <td>
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                onClick={() => navigate(`/user-roles/edit/${user.id}`, { state: { isEditMode: true, userId: user.id } })}
                                            >
                                                Edit
                                            </Button>{' '}
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleDelete(user.id)}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={isMarketingManager ? 6 : 7} className="text-center">
                                    No users available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <Pagination className="justify-content-end">
                {[...Array(totalPages)].map((_, i) => (
                    <Pagination.Item
                        key={i + 1}
                        active={i + 1 === currentPage}
                        onClick={() => handlePageChange(i + 1)}
                    >
                        {i + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        </>
    );
};

export default UserRoleTable;

