import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Header from './components/layout/Header/Header';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Offer from './pages/Offer/Offer';
import AddNewOffer from './pages/Offer/AddNewOffer/AddNewOffer';
import EditOffer from './pages/Offer/EditOffer/EditOffer';
import VehicleColors from './pages/CMS/VehicleColors/VehicleColors';
import AddNewColor from './pages/CMS/VehicleColors/AddNewColor/AddNewColor';
import EditColor from './pages/CMS/VehicleColors/EditColor/EditColor';
import CityManagement from './pages/CMS/CityManagement/CityManagement';
import AddNewCity from './pages/CMS/CityManagement/AddNewCity/AddNewCity';
import EditCity from './pages/CMS/CityManagement/EditCity/EditCity';
import SparePartsVariants from './pages/SparePartsManagement/SparePartsVariants/SparePartsVariants';
import AddNewSparePartsVariants from './pages/SparePartsManagement/SparePartsVariants/AddNewSparePartsVariants/AddNewSparePartsVariants';
import EditSparePartsVariants from './pages/SparePartsManagement/SparePartsVariants/EditSparePartsVariants/EditSparePartsVariants';
import SparePartsPricing from './pages/SparePartsManagement/SparePartsPricing/SparePartsPricing';
import AddNewSparePartsPricing from './pages/SparePartsManagement/SparePartsPricing/AddNewSparePartsPricing/AddNewSparePartsPricing';
import EditSparePartsPricing from './pages/SparePartsManagement/SparePartsPricing/EditSparePartsPricing/EditSparePartsPricing';
import MaintenanceCalculator from './pages/CMS/MaintenanceCalculator/MaintenanceCalculator';
import AddNewCalculation from './pages/CMS/MaintenanceCalculator/AddNewCalculation/AddNewCalculation';
import EditCalculation from './pages/CMS/MaintenanceCalculator/EditCalculation/EditCalculation';
import LocationManagement from './pages/CMS/LocationManagement/LocationManagement';
import AddNewLocation from './pages/CMS/LocationManagement/AddNewLocation/AddNewLocation';
import EditLocation from './pages/CMS/LocationManagement/EditLocation/EditLocation';
import UserRoles from './pages/UserRolesApproval/UserRoles/UserRoles';
import AddNewUser from './pages/UserRolesApproval/UserRoles/AddNewUser/AddNewUser';
import EditUserRole from './pages/UserRolesApproval/UserRoles/EditUserRole/EditUserRole';
import UserApproval from './pages/UserRolesApproval/UserApproval/UserApproval';
import SetHierarchy from './pages/UserRolesApproval/UserApproval/SetHierarchy';
import EditHierarchy from './pages/UserRolesApproval/UserApproval/EditHierarchy';
import ApprovalRequests from './pages/UserRolesApproval/UserApproval/ApprovalRequests';
import ApprovalTracking from './pages/UserRolesApproval/UserApproval/ApprovalTracking';
import VehicleManagement from './pages/VehicleManagement/VehicleManagement';
import AddNewVehicle from './pages/VehicleManagement/AddNewVehicle/AddNewVehicle';
import EditVehicle from './pages/VehicleManagement/EditVehicle/EditVehicle';
import BannerManagement from './pages/CMS/BannerManagement/BannerManagement';
import AddNewBanner from './pages/CMS/BannerManagement/AddNewBanner/AddNewBanner';
import EditBanner from './pages/CMS/BannerManagement/EditBanner/EditBanner';
import BlogManagement from './pages/CMS/BlogManagement/BlogManagement';
import AddNewBlog from './pages/CMS/BlogManagement/AddNewBlog/AddNewBlog';
import OfferView from './pages/Offer/OfferView/OfferView';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  // Check if the user is already authenticated on initial load
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    navigate('/dashboard'); // Redirect to dashboard upon successful login
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/login');
  };

  return (
    <>
      {isAuthenticated && <Header onLogout={handleLogout} />}
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />}
        />
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/dashboard" element={isAuthenticated ? <div>Dashboard Page</div> : <Navigate to="/login" />} />
       

        {/* Offer & Coupon Management */}
        <Route path="/offers" element={isAuthenticated ? <Offer /> : <Navigate to="/login" />} />
        <Route path="/offers/add-new" element={isAuthenticated ? <AddNewOffer /> : <Navigate to="/login" />} />
        <Route path="/offers/edit/:id" element={isAuthenticated ? <EditOffer /> : <Navigate to="/login" />} />
        <Route path="/offers/view/:id" element={isAuthenticated ? <OfferView /> : <Navigate to="/login" />} />

        {/* Product Management */}
        <Route path="/vehicle-management" element={isAuthenticated ? <VehicleManagement /> : <Navigate to="/login" />} />
        <Route path="/vehicle-management/add-new" element={isAuthenticated ? <AddNewVehicle /> : <Navigate to="/login" />} />
        <Route path="/vehicle-management/edit/:id" element={isAuthenticated ? <EditVehicle /> : <Navigate to="/login" />} />

        {/* Vehicle Colors route for CMS */}
        <Route path="/vehicle-colors" element={isAuthenticated ? <VehicleColors /> : <Navigate to="/login" />} />
        <Route path="/vehicle-colors/add-new" element={isAuthenticated ? <AddNewColor /> : <Navigate to="/login" />} />
        <Route path="/cms/vehicle-colors/edit/:id" element={isAuthenticated ? <EditColor /> : <Navigate to="/login" />} />

        {/* CMS Management */}
        <Route path="/cms/cities" element={isAuthenticated ? <CityManagement /> : <Navigate to="/login" />} />
        <Route path="/cms/cities/add-new" element={isAuthenticated ? <AddNewCity /> : <Navigate to="/login" />} />
        <Route path="/cms/cities/edit/:id" element={isAuthenticated ? <EditCity /> : <Navigate to="/login" />} />
        <Route path="/cms/maintenance-calculator" element={isAuthenticated ? <MaintenanceCalculator /> : <Navigate to="/login" />} />
        <Route path="/cms/maintenance-calculator/add-new" element={isAuthenticated ? <AddNewCalculation /> : <Navigate to="/login" />} />
        <Route path="/cms/maintenance-calculator/edit" element={isAuthenticated ? <EditCalculation /> : <Navigate to="/login" />} />
        <Route path="/cms/location-management" element={isAuthenticated ? <LocationManagement /> : <Navigate to="/login" />} />
        <Route path="/cms/location-management/add-new" element={isAuthenticated ? <AddNewLocation /> : <Navigate to="/login" />} />
        <Route path="/cms/location-management/edit/:id" element={isAuthenticated ? <EditLocation /> : <Navigate to="/login" />} />
        <Route path="/cms/banner-management" element={isAuthenticated ? <BannerManagement /> : <Navigate to="/login" />} />
        <Route path="/cms/banner-management/add-new" element={isAuthenticated ? <AddNewBanner /> : <Navigate to="/login" />} />
        <Route path="/cms/banner-management/edit/:id" element={isAuthenticated ? <EditBanner /> : <Navigate to="/login" />} />
        <Route path="/cms/blog-management" element={isAuthenticated ? <BlogManagement /> : <Navigate to="/login" />} />
        <Route path="/cms/blog-management/add-new" element={isAuthenticated ? <AddNewBlog /> : <Navigate to="/login" />} />

        {/* Spare Parts Management */}
        <Route path="/spare-parts/variants" element={isAuthenticated ? <SparePartsVariants /> : <Navigate to="/login" />} />
        <Route path="/spare-parts/variants/add" element={isAuthenticated ? <AddNewSparePartsVariants /> : <Navigate to="/login" />} />
        <Route path="/spare-parts-variants/edit/:id" element={isAuthenticated ? <EditSparePartsVariants /> : <Navigate to="/login" />} />
        <Route path="/spare-parts-management/spare-parts-pricing" element={isAuthenticated ? <SparePartsPricing /> : <Navigate to="/login" />} />
        <Route path="/spare-parts-management/spare-parts-pricing/add" element={isAuthenticated ? <AddNewSparePartsPricing /> : <Navigate to="/login" />} />
        <Route path="/spare-parts-management/spare-parts-pricing/edit/:id" element={isAuthenticated ? <EditSparePartsPricing /> : <Navigate to="/login" />} />

        {/* User Role & Approvals */}
        <Route path="/user-roles" element={isAuthenticated ? <UserRoles /> : <Navigate to="/login" />} />
        <Route path="/user-roles/add-new" element={isAuthenticated ? <AddNewUser /> : <Navigate to="/login" />} />
        <Route path="/user-roles/edit/:id" element={isAuthenticated ? <EditUserRole /> : <Navigate to="/login" />} />

        {/* User Approvals */}
        <Route path="/user-approvals" element={isAuthenticated ? <UserApproval /> : <Navigate to="/login" />} />
        <Route path="/user-approvals/set-hierarchy" element={isAuthenticated ? <SetHierarchy /> : <Navigate to="/login" />} />
        <Route path="/user-approvals/edit-hierarchy/:id" element={isAuthenticated ? <EditHierarchy /> : <Navigate to="/login" />} />
        <Route path="/user-approvals/approval-requests" element={isAuthenticated ? <ApprovalRequests /> : <Navigate to="/login" />} />
        <Route path="/user-approvals/approval-tracking" element={isAuthenticated ? <ApprovalTracking /> : <Navigate to="/login" />} />

        {/* Wildcard route */}
        <Route path="*" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
      </Routes>
    </>
  );
};

export default App;
