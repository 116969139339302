// ====== ALL MODULES SIDEBAR STARTS ================

// import React, { useState } from 'react';
// import './Sidebar.css';
// import { Home, User, Tag, Building, Wrench, Package, Banknote, Calculator, MapPin, CirclePercent, FolderCog, Palette, Users, ListChecks, MessageSquareWarning, Crosshair, Car, Images, Rss } from 'lucide-react';
// import { Link } from 'react-router-dom';

// const Sidebar = ({ closeOffCanvas }) => {
//     const [showOfferSubmenu, setShowOfferSubmenu] = useState(false);
//     const [showProductManagementSubmenu, setShowProductManagementSubmenu] = useState(false);
//     const [showCmsSubmenu, setShowCmsSubmenu] = useState(false);
//     const [showSparePartsSubmenu, setShowSparePartsSubmenu] = useState(false);
//     const [showUserApprovalSubmenu, setShowUserApprovalSubmenu] = useState(false);

//     const toggleOfferSubmenu = () => {
//         setShowOfferSubmenu(!showOfferSubmenu);
//     };

//     const toggleProductManagementSubmenu = () => {
//         setShowProductManagementSubmenu(!showProductManagementSubmenu);
//     };

//     const toggleCmsSubmenu = () => {
//         setShowCmsSubmenu(!showCmsSubmenu);
//     };

//     const toggleSparePartsSubmenu = () => {
//         setShowSparePartsSubmenu(!showSparePartsSubmenu);
//     };

//     const toggleUserApprovalSubmenu = () => {
//         setShowUserApprovalSubmenu(!showUserApprovalSubmenu);
//     };

//     return (
//         <div className="sidebar">
//             <ul className="sidebar-list">
//                 <li className="sidebar-item">
//                     <Home size={20} className="sidebar-icon" />
//                     <Link to="/dashboard" onClick={closeOffCanvas}>Dashboard</Link>
//                 </li>

//                 {/* Offer & Coupon Management */}
//                 <li className="sidebar-item" onClick={toggleOfferSubmenu}>
//                     <CirclePercent size={20} className="sidebar-icon" />
//                     <span>Offer & Coupon Management</span>
//                 </li>
//                 {showOfferSubmenu && (
//                     <ul className="submenu">
//                         <li className="sidebar-item">
//                             <Tag size={20} className="sidebar-icon" />
//                             <Link to="/offers" onClick={closeOffCanvas}>Offer</Link>
//                         </li>
//                     </ul>
//                 )}

//                 {/* Product Management */}
//                 {/* <li className="sidebar-item" onClick={toggleProductManagementSubmenu}>
//                     <Package size={20} className="sidebar-icon" />
//                     <span>Product Management</span>
//                 </li>
//                 {showProductManagementSubmenu && (
//                     <ul className='submenu'>
//                         <li className='sidebar-item'>
//                             <Car size={20} className="sidebar-icon" />
//                             <Link to="/vehicle-management" onClick={closeOffCanvas}>Vehicle Management</Link>
//                         </li>
//                     </ul>
//                 )} */}

//                 {/* CMS */}
//                 {/* <li className="sidebar-item" onClick={toggleCmsSubmenu}>
//                     <FolderCog size={20} className="sidebar-icon" />
//                     <span>CMS</span>
//                 </li>
//                 {showCmsSubmenu && (
//                     <ul className="submenu">
//                         <li className="sidebar-item">
//                             <Palette size={20} className="sidebar-icon" />
//                             <Link to="/vehicle-colors" onClick={closeOffCanvas}>Vehicle Colors</Link>
//                         </li>
//                         <li className="sidebar-item">
//                             <Building size={20} className="sidebar-icon" />
//                             <Link to="/cms/cities" onClick={closeOffCanvas}>City Management</Link>
//                         </li>
//                         <li className="sidebar-item">
//                             <Calculator size={20} className="sidebar-icon" />
//                             <Link to="/cms/maintenance-calculator" onClick={closeOffCanvas}>Maintenance Calculator</Link>
//                         </li>
//                         <li className="sidebar-item">
//                             <MapPin size={20} className="sidebar-icon" />
//                             <Link to="/cms/location-management" onClick={closeOffCanvas}>Location Management</Link>
//                         </li>
//                         <li className="sidebar-item">
//                             <Images size={20} className="sidebar-icon" />
//                             <Link to="/cms/banner-management" onClick={closeOffCanvas}>Banner Management</Link>
//                         </li>
//                         <li className="sidebar-item">
//                             <Rss size={20} className="sidebar-icon" />
//                             <Link to="/cms/blog-management" onClick={closeOffCanvas}>Blog Management</Link>
//                         </li>
//                     </ul>
//                 )} */}

//                 {/* Spare Parts Management */}
//                 {/* <li className="sidebar-item" onClick={toggleSparePartsSubmenu}>
//                     <Wrench size={20} className="sidebar-icon" />
//                     <span>Spare Parts Management</span>
//                 </li>
//                 {showSparePartsSubmenu && (
//                     <ul className="submenu">
//                         <li className="sidebar-item">
//                             <Package size={20} className="sidebar-icon" />
//                             <Link to="/spare-parts/variants" onClick={closeOffCanvas}>Spare Parts Variants</Link>
//                         </li>
//                         <li className="sidebar-item">
//                             <Banknote size={20} className="sidebar-icon" />
//                             <Link to="/spare-parts-management/spare-parts-pricing" onClick={closeOffCanvas}>Spare Parts Pricing</Link>
//                         </li>
//                     </ul>
//                 )} */}

//                 {/* User Role & Approvals */}
//                 <li className="sidebar-item" onClick={toggleUserApprovalSubmenu}>
//                     <User size={20} className="sidebar-icon" />
//                     <span>User Role & Approvals</span>
//                 </li>
//                 {showUserApprovalSubmenu && (
//                     <ul className="submenu">
//                         <li className="sidebar-item">
//                             <Users size={20} className="sidebar-icon" />
//                             <Link to="/user-roles" onClick={closeOffCanvas}>User Roles</Link>
//                         </li>
//                         {/* <li className="sidebar-item">
//                             <ListChecks size={20} className="sidebar-icon" />
//                             <Link to="/user-approvals" onClick={closeOffCanvas}>User Approvals</Link>
//                         </li> */}
//                         <li className="sidebar-item">
//                             <MessageSquareWarning size={20} className="sidebar-icon" />
//                             <Link to="/user-approvals/approval-requests" onClick={closeOffCanvas}>Approval Requests</Link>
//                         </li>
//                         <li className="sidebar-item">
//                             <Crosshair size={20} className="sidebar-icon" />
//                             <Link to="/user-approvals/approval-tracking" onClick={closeOffCanvas}>Approval Tracking</Link>
//                         </li>
//                     </ul>
//                 )}
//             </ul>
//         </div>
//     );
// };

// export default Sidebar;



// ====== ALL MODULES SIDEBAR ENDS ================


import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { Home, User, Tag, Building, Wrench, Package, Banknote, Calculator, MapPin, CirclePercent, FolderCog, Palette, Users, ListChecks, MessageSquareWarning, Crosshair, Car, Images, Rss } from 'lucide-react';
import { Link } from 'react-router-dom';

const Sidebar = ({ closeOffCanvas }) => {
    const [showOfferSubmenu, setShowOfferSubmenu] = useState(false);
    const [showProductManagementSubmenu, setShowProductManagementSubmenu] = useState(false);
    const [showCmsSubmenu, setShowCmsSubmenu] = useState(false);
    const [showSparePartsSubmenu, setShowSparePartsSubmenu] = useState(false);
    const [showUserApprovalSubmenu, setShowUserApprovalSubmenu] = useState(false);
    const [userRole, setUserRole] = useState(null);

    // Get user role from localStorage on component mount
    useEffect(() => {
        const storedRole = localStorage.getItem('userRoles');
        const role = storedRole ? JSON.parse(storedRole)[0] : null;
        setUserRole(role);
    }, []);

    const toggleOfferSubmenu = () => {
        setShowOfferSubmenu(!showOfferSubmenu);
    };

    const toggleProductManagementSubmenu = () => {
        setShowProductManagementSubmenu(!showProductManagementSubmenu);
    };

    const toggleCmsSubmenu = () => {
        setShowCmsSubmenu(!showCmsSubmenu);
    };

    const toggleSparePartsSubmenu = () => {
        setShowSparePartsSubmenu(!showSparePartsSubmenu);
    };

    const toggleUserApprovalSubmenu = () => {
        setShowUserApprovalSubmenu(!showUserApprovalSubmenu);
    };

    return (
        <div className="sidebar">
            <ul className="sidebar-list">
                <li className="sidebar-item">
                    <Home size={20} className="sidebar-icon" />
                    <Link to="/dashboard" onClick={closeOffCanvas}>Dashboard</Link>
                </li>

                {/* Conditional Rendering for mr-user */}
                {userRole === 'mr-user' ? (
                    <>
                        {/* Offer & Coupon Management */}
                        <li className="sidebar-item" onClick={toggleOfferSubmenu}>
                            <CirclePercent size={20} className="sidebar-icon" />
                            <span>Offer & Coupon Management</span>
                        </li>
                        {showOfferSubmenu && (
                            <ul className="submenu">
                                <li className="sidebar-item">
                                    <Tag size={20} className="sidebar-icon" />
                                    <Link to="/offers" onClick={closeOffCanvas}>Offer</Link>
                                </li>
                            </ul>
                        )}

                        {/* User Role & Approvals - Only Approval Tracking */}
                        <li className="sidebar-item" onClick={toggleUserApprovalSubmenu}>
                            <User size={20} className="sidebar-icon" />
                            <span>User Role & Approvals</span>
                        </li>
                        {showUserApprovalSubmenu && (
                            <ul className="submenu">
                                <li className="sidebar-item">
                                    <Crosshair size={20} className="sidebar-icon" />
                                    <Link to="/user-approvals/approval-tracking" onClick={closeOffCanvas}>Approval Tracking</Link>
                                </li>
                            </ul>
                        )}
                    </>
                ) : (
                    <>
                        {/* Full Sidebar for other roles */}

                        {/* Offer & Coupon Management */}
                        <li className="sidebar-item" onClick={toggleOfferSubmenu}>
                            <CirclePercent size={20} className="sidebar-icon" />
                            <span>Offer & Coupon Management</span>
                        </li>
                        {showOfferSubmenu && (
                            <ul className="submenu">
                                <li className="sidebar-item">
                                    <Tag size={20} className="sidebar-icon" />
                                    <Link to="/offers" onClick={closeOffCanvas}>Offer</Link>
                                </li>
                            </ul>
                        )}

                        {/* Product Management */}
                        {/* <li className="sidebar-item" onClick={toggleProductManagementSubmenu}>
                            <Package size={20} className="sidebar-icon" />
                            <span>Product Management</span>
                        </li>
                        {showProductManagementSubmenu && (
                            <ul className='submenu'>
                                <li className='sidebar-item'>
                                    <Car size={20} className="sidebar-icon" />
                                    <Link to="/vehicle-management" onClick={closeOffCanvas}>Vehicle Management</Link>
                                </li>
                            </ul>
                        )} */}

                        {/* CMS */}
                        {/* <li className="sidebar-item" onClick={toggleCmsSubmenu}>
                            <FolderCog size={20} className="sidebar-icon" />
                            <span>CMS</span>
                        </li>
                        {showCmsSubmenu && (
                            <ul className="submenu">
                                <li className="sidebar-item">
                                    <Palette size={20} className="sidebar-icon" />
                                    <Link to="/vehicle-colors" onClick={closeOffCanvas}>Vehicle Colors</Link>
                                </li>
                                <li className="sidebar-item">
                                    <Building size={20} className="sidebar-icon" />
                                    <Link to="/cms/cities" onClick={closeOffCanvas}>City Management</Link>
                                </li>
                                <li className="sidebar-item">
                                    <Calculator size={20} className="sidebar-icon" />
                                    <Link to="/cms/maintenance-calculator" onClick={closeOffCanvas}>Maintenance Calculator</Link>
                                </li>
                                <li className="sidebar-item">
                                    <MapPin size={20} className="sidebar-icon" />
                                    <Link to="/cms/location-management" onClick={closeOffCanvas}>Location Management</Link>
                                </li>
                                <li className="sidebar-item">
                                    <Images size={20} className="sidebar-icon" />
                                    <Link to="/cms/banner-management" onClick={closeOffCanvas}>Banner Management</Link>
                                </li>
                                <li className="sidebar-item">
                                    <Rss size={20} className="sidebar-icon" />
                                    <Link to="/cms/blog-management" onClick={closeOffCanvas}>Blog Management</Link>
                                </li>
                            </ul>
                        )} */}

                        {/* Spare Parts Management */}
                        {/* <li className="sidebar-item" onClick={toggleSparePartsSubmenu}>
                            <Wrench size={20} className="sidebar-icon" />
                            <span>Spare Parts Management</span>
                        </li>
                        {showSparePartsSubmenu && (
                            <ul className="submenu">
                                <li className="sidebar-item">
                                    <Package size={20} className="sidebar-icon" />
                                    <Link to="/spare-parts/variants" onClick={closeOffCanvas}>Spare Parts Variants</Link>
                                </li>
                                <li className="sidebar-item">
                                    <Banknote size={20} className="sidebar-icon" />
                                    <Link to="/spare-parts-management/spare-parts-pricing" onClick={closeOffCanvas}>Spare Parts Pricing</Link>
                                </li>
                            </ul>
                        )} */}

                        {/* User Role & Approvals */}
                        <li className="sidebar-item" onClick={toggleUserApprovalSubmenu}>
                            <User size={20} className="sidebar-icon" />
                            <span>User Role & Approvals</span>
                        </li>
                        {showUserApprovalSubmenu && (
                            <ul className="submenu">
                                <li className="sidebar-item">
                                    <Users size={20} className="sidebar-icon" />
                                    <Link to="/user-roles" onClick={closeOffCanvas}>User Roles</Link>
                                </li>
                                {/* <li className="sidebar-item">
                                    <ListChecks size={20} className="sidebar-icon" />
                                    <Link to="/user-approvals" onClick={closeOffCanvas}>User Approvals</Link>
                                </li> */}
                                <li className="sidebar-item">
                                    <MessageSquareWarning size={20} className="sidebar-icon" />
                                    <Link to="/user-approvals/approval-requests" onClick={closeOffCanvas}>Approval Requests</Link>
                                </li>
                                <li className="sidebar-item">
                                    <Crosshair size={20} className="sidebar-icon" />
                                    <Link to="/user-approvals/approval-tracking" onClick={closeOffCanvas}>Approval Tracking</Link>
                                </li>
                            </ul>
                        )}
                    </>
                )}
            </ul>
        </div>
    );
};

export default Sidebar;